import {isLDFeatureFlagOn} from './../utils/feature-flag-helpers';
import {isLocal} from '@utils/environment-helpers';
import {
  FeatureFlagExperiments,
  FeatureFlagTreatments,
  getLocalHostTreatment,
} from '@utils/feature-flag-helpers';
import {useFlags} from 'launchdarkly-react-client-sdk';
/*
|--------------------------------------------------------------------------
| When a feature flag is added, this hook automatically gives access to its status
|--------------------------------------------------------------------------
*/

export type TIsFeatureFlagOn = Record<
  keyof typeof FeatureFlagExperiments,
  boolean
>;

/**
 * @example
 *
 * import useFeatureFlags from '@hooks/useFeatureFlags';
 *
 * const {isFeatureFlagOn} = useFeatureFlags();
 */

const useFeatureFlags = (
  isFlagOnInControlCase?: boolean
): {isFeatureFlagOn: TIsFeatureFlagOn} => {
  const ldflags = useFlags();

  // Get treatment using Launch Darkly api unless doing local development
  const treatment = (flagkey: string) => {
    return isLocal
      ? getLocalHostTreatment(flagkey)
      : isLDFeatureFlagOn(ldflags[flagkey]);
  };

  const isFeatureFlagOn: any = Object.keys(FeatureFlagExperiments).reduce(
    (flagStatuses, flag: FeatureFlagExperiments) => {
      flagStatuses[flag] = (() => {
        // Rule skipped: This hook is validly calling another hook
        // eslint-disable-next-line react-hooks/rules-of-hooks
        switch (treatment(FeatureFlagExperiments[flag])) {
          case FeatureFlagTreatments.On:
            return true;
          case FeatureFlagTreatments.Off:
            return false;
          case FeatureFlagTreatments.Control:
            return !!isFlagOnInControlCase;
          default:
            return false;
        }
      })();
      return flagStatuses;
    },
    {}
  );
  return {isFeatureFlagOn};
};

export default useFeatureFlags;
