import {useIsIntegratedUser} from './useIsIntegratedUser';
import {useIsLxpAndAcademiesUser} from './useIsLxpAndAcademiesUser';
import {UserDetailsVM} from '@generated/interfaces';
import {TIsFeatureFlagOn} from '@hooks/useFeatureFlags';
import {
  UserPaths,
  UserProgramsPageTab,
  getUserProgramsBasePath,
} from '@utils/ClientPaths';

export interface UrlPath {
  path: string;
  enabled?: (user?: UserDetailsVM) => boolean;
}

export const useGetUrlIncludesList = (
  user: UserDetailsVM,
  isFeatureFlagOn: TIsFeatureFlagOn
): UrlPath[] => {
  const isIntegratedUser = useIsIntegratedUser(
    isFeatureFlagOn.UpdatedLxpToCmFlow,
    user
  );

  const isLxpAndAcademiesUser = useIsLxpAndAcademiesUser(
    user?.isAcademiesIntegratedExperienceDisabled
  );

  const AcmNavFlags =
    isFeatureFlagOn.HidePrimaryNav ||
    isFeatureFlagOn.AcademyAndCpLxpFlow ||
    isFeatureFlagOn.LxpToCmFlow ||
    isLxpAndAcademiesUser ||
    isIntegratedUser;
  // TODO: Remove HidePrimaryNav and use AcademyAndCpLxpFlow for shared LXP and ACM clients

  const commonEnabledExpression =
    isFeatureFlagOn.LxpToCmFlow || isIntegratedUser || isLxpAndAcademiesUser;

  return [
    {
      path: getUserProgramsBasePath(UserProgramsPageTab.Custom),
      enabled: () => AcmNavFlags,
    },
    {
      path: UserPaths.Academies,
      enabled: () => AcmNavFlags,
    },
    {
      path: UserPaths.WelcomeAcademy,
      enabled: () => AcmNavFlags,
    },
    {
      path: UserPaths.Index,
      enabled: () => commonEnabledExpression,
    },
    {
      path: UserPaths.ManagePlan,
      enabled: () => commonEnabledExpression,
    },
    {
      path: UserPaths.ProgramDetail,
      enabled: () => commonEnabledExpression,
    },
    {
      path: UserPaths.Settings,
      enabled: () => commonEnabledExpression,
    },
  ];
};
