import {createRoot} from 'react-dom/client';
import {AuthProvider} from '@utils/oidc-auth-wrapper';
import DatadogErrorBoundary from '@components/reusable/ErrorBoundary/DataDogErrorBoundary';
import App from './App';

// Load Tailwind CSS
import './assets/tailwind.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <DatadogErrorBoundary>
    <AuthProvider>
      <App />
    </AuthProvider>
  </DatadogErrorBoundary>
);
